const de = {
    configTitle: 'Smart Insurance Konfiguration',
    canceledAt: 'Sie haben Ihre Smart Insurance gekündigt ',
    upperP: 'Indem die Mobiliar Informationen über versicherungsrelevante Änderungen erhält, werden alle notwendigen Anpassungen für die Aktualisierung Ihrer Versicherungen vorbereitet. So sind Sie immer richtig versichert. Standardmässig sind diese Konten in bexio wie folgt eingestellt:',
    turnover: "Umsatz",
    turnoverValue: "Betrieblicher Ertrag aus Lieferungen und Leistungen (3)",
    payroll: "Gehaltsabrechnung",
    payrollValue: "Lohnaufwand (5000)",
    inventory: "Bestandsaufnahme",
    inventoryValue: "Vorräte und nicht fakturierte Dienstleistungen (120)",
    fixedAssets: "Anlagevermögen",
    fixedAssetsValue: "Mobile Sachanlagen (150)",
    mappingP:
        "Wenn Sie oder Ihr Buchhalter die Standardkonten geändert haben, ordnen Sie bitte jede Kategorie dem richtigen Konto zu:",
    canceling: 'Smart Insurance kündigen:',
    cancelingP:
        'Wenn Sie den Versicherungsschutz von Smart Insurance nicht mehr in Anspruch nehmen möchten, ',
    clickHere: "klicken Sie hier",
    terms: "Bedingungen & Konditionen:",
    save: "Speichern",
    cancel: 'Wenn Sie Smart Insurance kündigen möchten, klicken Sie hier',
    errorCanceling:
        'Beim Stornieren Ihrer Smart Insurance ist ein Fehler aufgetreten.',
    successSettings: "Ihre Einstellungen wurden erfolgreich gespeichert!",
    errorRetrieve:
        "Beim Abrufen Ihrer Einstellungen ist ein Fehler aufgetreten.",
    none: "Keiner",
    bexioLogin: "Melden Sie sich mit dem Bexio Konto an",
    conditions: "Bedingungen",
    cancelBtn: "Stornieren",
    approve: "Genehmigen",
    errorLogin: "Beim Versuch, Sie anzumelden, ist ein Fehler aufgetreten.",
    tryAgain: "Versuch es noch einmal",
    logout: "Abmelden",
    english: "Englisch",
    german: "Deutsch",
    cancelConfirm: 'Möchten Sie Smart Insurance wirklich kündigen?',
    revoke1:
        "Sie können die Zugriff von Smart Insurance auf Ihr bexio Daten ",
    revokeHere: "hier",
    revoke2: " wiederrufen. ",
    restore: "Wiederherstellen",
    errorRestore:
        "Beim Versuch, Ihre Einstellungen wiederherzustellen, ist ein Fehler aufgetreten.",
    termsView: "Um die Bedingungen und Konditionen einzusehen, ",
    goConfig: "Zur Konfiguration gehen",
    loginSuccess: "Erfolgreich eingeloggt!",
    conditionTitle: "Einwilligungstext",
    conditionPart1: "Ich verfüge über eine Betriebsversicherung bei der Mobiliar. Ich willige ein, dass bexio den Firmennamen, die Adresse sowie die UID (Unternehmens-Identifikationsnummer) zwecks Prüfung des bestehenden Versicherungsvertrags an die Mobiliar übermittelt.",
    conditionPart2: "Ich nutze (allenfalls in Zusammenarbeit mit meinem Treuhänder) regelmässig die Buchhaltung in bexio (mindestens im Rahmen des jährlichen Abschlusses). Der Umsatz, die Lohndaten sowie das Inventar sind stets auf dem aktuellen Stand.",
    conditionPart3: "Ich willige ein, dass bexio die versicherungsrelevanten Veränderungen, namentlich bei Jahresumsatz, AHV-Lohnsummen, Inventar sowie Warenlager (sofern vorhanden), an die Mobiliar weiterleitet. Der Jahresumsatz und die AHV-Jahreslohnsumme werden von der Mobiliar einmal jährlich mit den Werten meiner Versicherungspolice abgeglichen. Inventarveränderungen und Veränderungen beim Warenlager (falls vorhanden) werden regelmässig beobachtet. Bei relevanten Veränderungen (+/- 10 % gegenüber dem Vorjahr) erfolgt jeweils eine Mitteilung von bexio an die Mobiliar. Die Mobiliar wird befugt, die für meine Versicherungsdeckung berücksichtigten Unternehmenswerte (Jahresumsatz, AHV-Jahreslohnsumme, Inventarsumme, Warenlager falls vorhanden) zu Informationszwecken an bexio weiterzugeben.",
    conditionPart4: "Ich nehme zu Kenntnis, dass die Aktivierung bzw. Deaktivierung der App “Smart Insurance” Änderungen an meiner bestehenden Betriebsversicherung zur Folge hat. Mit Aktivierung der App “Smart Insurance” stimme ich der Anpassung meiner Betriebsversicherung dahingehend zu, dass die so genannte individuelle Besondere Bedingung (iBB) zum Verzicht auf die Anrechnung einer allfälligen Unterversicherung in den Versicherungsvertrag integriert wird. Für die Vertragsanpassung erfolgt die Kontaktaufnahme durch den zuständigen Versicherungsberater der Mobiliar. Bei einer Deaktivierung der App “Smart Insurance”, bei Nichterfüllung der Nutzungsbedingungen (Nutzung der Buchhaltung in bexio) oder wenn die Vertragsbeziehung zu bexio beendet wird, ist die Mobiliar berechtigt, den Versicherungsvertrag einseitig anzupassen und die iBB wieder auszuschliessen. Ich nehme zur Kenntnis, dass weder der Ein- noch Ausschluss der iBB zur Kündigung berechtigen, auch wenn dies allfällige Prämienanpassungen zur Folge hat. In diesem Zusammenhang stimme ich der Wegbedingung des entsprechenden Passus der AVB zu. Die Mobiliar verpflichtet sich, die im Rahmen von “Smart Insurance” erhaltenen Daten vertraulich zu behandeln. Beide Parteien können die Vereinbarung jederzeit widerrufen. Als Nutzer von bexio können Sie dies ganz einfach durch Entfernung der Mobiliar App in ihren verknüpften Apps vornehmen.",
    conditionPart5: "BB “Smart Insurance” für bexio-Kunden",
    conditionPart6: "Die Mobiliar verzichtet in teilweiser Abänderung der Allgemeinen Bedingungen auf die Berechnung einer allfälligen Unterversicherung. Bei Elementarschäden an Sachen, die der Verordnung über die Beaufsichtigung von privaten Versicherungsunternehmen (AVO) unterliegen, erfolgt die Entschädigung des nicht in Abzug gebrachten Unterversicherungsbetrages im Rahmen der im entsprechenden Baustein-Beschrieb aufgeführten Kosten und Zusatzleistungen (nicht AVO). Nach einem Schadenfall ist die Mobiliar jedoch berechtigt, die aufgrund der Unterversicherung eingesparte Prämie für das laufende Versicherungsjahr zu verrechnen und die Berichtigung des Vertrages zu verlangen.",
    conditionPart7: "*Betriebs- und Gebäudeversicherung der Mobiliar: Aufhebung der Versicherung – Bei Änderung der Prämientarife und Selbstbehalte: Wir können die Anpassung der Versicherungen verlangen, wenn wir die Prämientarife oder die Regelungen der Selbstbehalte ändern. Dazu geben wir Ihnen die Änderungen spätestens 25 Tage vor Ende des Versicherungsjahres bekannt. Sie können, wenn Sie mit der Änderung nicht einverstanden sind, den davon betroffenen Teil Ihrer Police kündigen. Ihre Kündigung ist gültig, wenn sie spätestens am letzten Tag des Versicherungsjahres bei uns eintrifft. Unterlassen Sie die Kündigung, gilt dies als Zustimmung zur Anpassung."
};

export default de;
