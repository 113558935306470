import React, { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { makeStyles, Link, Button } from "@material-ui/core";
import settingsService from "../../../../services/settings";

export default function Canceled(props: { canceled: Date; restored: any }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);

    const restore = async () => {
        const resp = await settingsService.restore();
        if (!resp) {
            setError(t("errorRestore"));
            return;
        }
        props.restored();
    };

    return (
        <>
            <Alert
                className={classes.cancelAlert}
                severity="success"
                color={"error"}>
                <div className={classes.alertBody}>
                    <div>
                        {t("canceledAt")} {props.canceled.toLocaleString()}
                    </div>
                    <Button onClick={restore}>{t("restore")}</Button>
                </div>
            </Alert>

            <p className={classes.revokeP}>
                {t("revoke1")}{" "}
                <Link
                    color="secondary"
                    href="https://office.bexio.com/index.php/admin/apps">
                    {t("revokeHere")}
                </Link>
                {t("revoke2")}
            </p>
            {error && <h5 className={classes.error}>{error}</h5>}
        </>
    );
}

const useStyles = makeStyles({
    cancelAlert: {
        fontSize: 16,
        alignItems: "center",
        "& .MuiAlert-message": { width: "100%" },
    },
    error: {
        color: "#F55448",
    },
    revokeP: {
        fontSize: 14,
        paddingLeft: 20,
    },
    alertBody: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
});
