import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import Header from "../../Components/Header/Header";
import { makeStyles, Container, Grid } from "@material-ui/core";
import Configuration from "./Configuration/Configuration";

export default function Dashboard() {
    const classes = useStyles();

    let match = useRouteMatch();
    return (
        <Router>
            <Header showLogout />
            <Container className={classes.container}>
                <Grid container justify="center">
                    <Switch>
                        <Route path={`${match.path}/`}>
                            <Configuration />
                        </Route>
                    </Switch>
                </Grid>
            </Container>
        </Router>
    );
}

const useStyles = makeStyles({
    container: {
        marginTop: 30,
        marginBottom: 30,
    },
});
