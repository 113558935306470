import getAxiosInstance from "./httpClient";
import { Account } from "../Views/Dashboard/Configuration/ConfigForm/accounts.interface";

const settingsService = {
    getData: async (): Promise<{
        accounts: Account[];
        accountGroups: Account[];
        settings: any;
    }> => {
        try {
            let requests = await Promise.all([
                await settingsService.getAccounts(),
                await settingsService.getAccountGroups(),
                await settingsService.getSettings(),
            ]);

            const dataAccounts: Account[] = requests[0];
            if (!dataAccounts) throw new Error();

            const dataAccountGroups: Account[] = requests[1];
            if (!dataAccountGroups) throw new Error();

            const dataSettings = requests[2];
            if (!dataSettings) throw new Error();

            return {
                accounts: dataAccounts,
                accountGroups: dataAccountGroups,
                settings: dataSettings,
            };
        } catch (err) {
            console.log("getData -> err", err);
            throw new Error();
        }
    },

    getAccounts: async () => {
        try {
            const resp = await getAxiosInstance().get(
                "/mobiliar/bexio-accounts"
            );
            return resp.data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },
    getAccountGroups: async () => {
        try {
            const resp = await getAxiosInstance().get(
                "/mobiliar/bexio-account-groups"
            );
            return resp.data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },
    getSettings: async () => {
        try {
            const resp = await getAxiosInstance().get("/mobiliar/settings");
            return resp.data.settings;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },
    setSettings: async (settings: any) => {
        try {
            const resp = await getAxiosInstance().put(
                "/mobiliar/settings",
                settings
            );
            return resp.data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },

    cancel: async () => {
        try {
            const resp = await getAxiosInstance().delete("/mobiliar/cancel");
            return resp.data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },
    restore: async () => {
        try {
            const resp = await getAxiosInstance().put("/mobiliar/cancel");
            return resp.data;
        } catch (error) {
            console.log("error", error);
            return false;
        }
    },
};

export default settingsService;
