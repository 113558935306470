import React from "react";
import i18next from "../../i18n/i18n";
import ScrollArea from "react-scrollbar";
import {useTranslation} from "react-i18next";

export default function ConditionsText() {
    const { t } = useTranslation();

    return (
        <ScrollArea style={{ maxHeight: "55vh", fontSize: 14 }} smoothScrolling>
            <h4 style={{ fontSize: 14 }}>{t('conditionTitle')}</h4>
            <ul>
                <li>
                    {t('conditionPart1')};
                </li>
                <li>
                    {t('conditionPart2')};
                </li>
                <li>
                    {t('conditionPart3')};
                </li>
                <li>
                    {t('conditionPart4')};
                </li>
            </ul>
            <h4 style={{ fontSize: 14 }}>
                {t('conditionPart5')};
            </h4>
            <p>
                {t('conditionPart6')};
            </p>
            <p style={{ fontSize: 12 }}>
                {t('conditionPart7')};
            </p>
        </ScrollArea>
    );
}
