import React from "react";
import logo from "../../assets/mobiliar-logo.png";
import logoFr from "../../assets/mobi_logo_fr.svg";
import { Button, makeStyles, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select/Select";
import FormControl from '@material-ui/core/FormControl';
import i18next from "../../i18n/i18n";

function Header(props: { absolute?: boolean; showLogout?: boolean }) {
    const { t } = useTranslation();
    const classes = useStyles();

    const logout = async () => {
        localStorage.removeItem("access_token");
        window.location.href = "/";
    };

    return (
        <nav
            style={
                props.absolute
                    ? {
                          position: "absolute",
                          backgroundColor: "transparent",
                          width: "80%",
                      }
                    : {
                          position: "relative",
                          backgroundColor: "white",
                      }
            }
            className={classes.nav}>
            <div className={classes.leftDiv}>
                <img className={classes.logo} src={ i18next.language === "fr" ? logoFr : logo} alt="Logo" />
                <h1 className={classes.title}>
                    {process.env.REACT_APP_WEBSITE_NAME}
                </h1>
            </div>
            <div style={{display:"flex"}}>
                <FormControl style={{marginRight:10}}>
                    <Select
                        value={i18next.language && i18next.language.toLowerCase().indexOf('fr') > -1 ? 'fr': 'de'}
                        onChange={(e:any) => {
                            i18next.changeLanguage(e?.target?.value);
                        }}
                    >
                        <MenuItem value="de">
                            DE
                        </MenuItem>
                        <MenuItem value="fr">
                            FR
                        </MenuItem>
                    </Select>
                </FormControl>
                {props.showLogout && (
                    <Button color="secondary" onClick={logout}>
                        {t("logout")}
                    </Button>
                )}
            </div>
        </nav>
    );
}

const useStyles = makeStyles({
    leftDiv: {
        display: "flex",
    },
    nav: {
        display: "flex",
        padding: "10px 100px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    logo: {
        width: 130,
    },
    title: {
        marginLeft: 20,
        fontFamily: "Roboto",
    },
});

export default Header;
