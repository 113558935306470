import React, { useState } from "react";
import Header from "../../../Components/Header/Header";
import "./Login.css";
import bexioLogo from "../../../assets/bexio-logo.png";

import { Button, withStyles, CircularProgress } from "@material-ui/core";
import authService from "../../../services/auth";
import { useTranslation } from "react-i18next";
const BexioButton = withStyles({
    root: {
        position: "absolute",
        top: "50%",
        backgroundColor: "#b9ce1e",
        left: "50%",
        color: "#12343F",
        transform: "translate(-50%, -50%)",
        "&:hover": {
            boxShadow: "0px 10px 15px 0px rgba(176,176,176,.5)",
            backgroundColor: "#9bad14",
        },
    },
})(Button);
export default function Login() {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const onClick = async () => {
        setLoading(true);
        const url = await authService.getURL();
        window.open(url, "_self");
        setLoading(false);
    };

    return (
        <>
            <Header absolute />
            <div className="container">
                <BexioButton
                    onClick={onClick}
                    size="large"
                    variant="contained"
                    startIcon={
                        loading ? (
                            <CircularProgress size="25px" color="inherit" />
                        ) : (
                            <img src={bexioLogo} alt="" className="btn-logo" />
                        )
                    }>
                    {t("bexioLogin")}
                </BexioButton>
            </div>
        </>
    );
}
