import React from "react";
import { Accounts, Account } from "./accounts.interface";
import {
    Grid,
    makeStyles,
    Button,
    CircularProgress,
    Link,
} from "@material-ui/core";
import Select from "react-select";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { Check } from "@material-ui/icons";

export default function ConfigForm(props: {
    settings: Accounts;
    accountChange: any;
    accounts: Account[];
    accountGroups: Account[];
    handleSave: any;
    sending: boolean;
    message: string;
    success: boolean;
}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (field_name: string, newValue: any) => {
        console.log(
            "handleChange -> field_name, newValue.value",
            field_name,
            newValue.value
        );
        props.accountChange(field_name, newValue.value);
    };

    const getAccountAsOption = (account: Account | null) => {
        if (!account || account.number === 0)
            return { value: 0, label: t("none") };
        return {
            label: `${account.name} (${account.number})`,
            value: account.number,
        };
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} container spacing={10} alignItems="center">
                <Grid item sm={3}>
                    <label className={classes.label}>{t("turnover")}:</label>
                </Grid>
                <Grid item sm={9}>
                    <Select
                        value={getAccountAsOption(props.settings.turnover)}
                        onChange={(value) => handleChange("turnover", value)}
                        options={props.accountGroups?.map((account) => ({
                            label: `${account.name} (${account.number})`,
                            value: account.number,
                        }))}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container spacing={10} alignItems="center">
                <Grid item sm={3}>
                    <label className={classes.label}>{t("payroll")}:</label>
                </Grid>
                <Grid item sm={9}>
                    <Select
                        value={getAccountAsOption(props.settings.payroll)}
                        onChange={(value) => handleChange("payroll", value)}
                        options={props.accounts?.map((account) => ({
                            label: `${account.name}${
                                account.number !== 0
                                    ? " (" + account.number + ") "
                                    : ""
                            }`,
                            value: account.number,
                        }))}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container spacing={10} alignItems="center">
                <Grid item sm={3}>
                    <label className={classes.label}>{t("inventory")}:</label>
                </Grid>
                <Grid item sm={9}>
                    <Select
                        value={getAccountAsOption(props.settings.inventory)}
                        onChange={(value) => handleChange("inventory", value)}
                        options={props.accountGroups?.map((account) => ({
                            label: `${account.name} (${account.number})`,
                            value: account.number,
                        }))}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container spacing={10} alignItems="center">
                <Grid item sm={3}>
                    <label className={classes.label}>{t("fixedAssets")}:</label>
                </Grid>
                <Grid item sm={9}>
                    <Select
                        value={getAccountAsOption(props.settings.fixedAssets)}
                        onChange={(value) => handleChange("fixedAssets", value)}
                        options={props.accountGroups?.map((account) => ({
                            label: `${account.name} (${account.number})`,
                            value: account.number,
                        }))}
                    />
                </Grid>
            </Grid>

            <div className={classes.buttonCont}>
                {props.success && (
                    <Alert
                        className={classes.alert}
                        severity="success"
                        color="info">
                        {props.message === "success" ? (
                            <div>
                                Vielen Dank, dass Sie Smart Insurance aktiviert
                                haben. Sie sind ab sofort automatisch immer
                                passend versichert. Ihre Generalagentur der
                                Mobiliar wird Sie in Kürze für die erforderliche
                                Vertragsanpassung kontaktieren.{" "}
                                <Link
                                    color="secondary"
                                    style={{ display: "block" }}
                                    href="http://office.bexio.com/">
                                    Zurück zu bexio
                                </Link>
                            </div>
                        ) : (
                            props.message
                        )}
                    </Alert>
                )}

                <Button
                    variant="outlined"
                    size="large"
                    className={classes.button}
                    onClick={props.handleSave}
                    startIcon={
                        props.sending ? (
                            <CircularProgress color="inherit" size="0.875rem" />
                        ) : (
                            <Check />
                        )
                    }>
                    {t('save')}
                </Button>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles({
    container: {
        display: "flex",
        paddingTop: 20,
        paddingBottom: 20,
        justifyContent: "center",
    },
    label: {
        fontWeight: "bold",
    },
    buttonCont: { textAlign: "center" },
    button: {
        marginTop: 10,
    },
    alert: {
        marginTop: 10,
    },
});
