import getAxiosInstance from "./httpClient";

const authService = {
  getURL: async () => {
    try {
      const resp = await getAxiosInstance().get("/auth/login/bexio");
      localStorage.setItem("codeVerifier", resp.data.codeVerifier);
      return resp.data.url;
    } catch (error) {
      console.log("error", error);
    }
  },
  verifyCode: async (url: string, codeVerifier: string) => {
    try {
      const resp = await getAxiosInstance().post("/auth/login/bexio/post", {
        url,
        codeVerifier,
      });
      localStorage.setItem("access_token", resp.data.jwt);
      return true;
    } catch (error) {
      console.log("error", error);
      return false;
    }
  },
  isAuthenticated: () => {
    return !!localStorage.getItem("access_token");
  },
};

export default authService;
