import React, { useState } from "react";
import Header from "../../../Components/Header/Header";
import "./GTC.css";
import { Close, Check } from "@material-ui/icons";
import {
    Button,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
    makeStyles,
    CardHeader,
} from "@material-ui/core";
import authService from "../../../services/auth";
import { useTranslation } from "react-i18next";
import ConditionsText from "../../../Components/ConditionsText/ConditionsText";

const useStyles = makeStyles({
    card: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    },
    actions: {
        justifyContent: "flex-end",
    },
});

export default function Conditions() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const onApprove = async () => {
        setLoading(true);
        const url = await authService.getURL();
        window.open(url, "_self");
        setLoading(false);
    };

    const onCancel = () => {
        window.history.back();
    };

    return (
        <>
            <Header absolute />
            <div className="container">
                <Card className={classes.card}>
                    <CardHeader title={t("conditions")} />
                    <CardContent>
                        <ConditionsText />
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button onClick={onCancel} startIcon={<Close />}>
                            {t("cancelBtn")}
                        </Button>
                        <Button
                            onClick={onApprove}
                            startIcon={
                                loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size="0.875rem"
                                    />
                                ) : (
                                    <Check />
                                )
                            }>
                            {t("approve")}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
}
