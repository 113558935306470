//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1vdWFkLmtoY2hpY2hAZ21haWwuY29tIiwicm9sZXMiOlsiYmV4aW8tbW9iaWxpYXIiXSwibGFuZ3VhZ2UiOiJlbl9HQiIsImNvbXBhbnkiOiI1ZWM3ZGQ0MDM1ZmM1ZjMwZjBlMjQzMjIiLCJleHAiOjE1OTU3NzkyNjEuMjk2LCJpYXQiOjE1OTA1OTUyNjF9.aZwjI_BSAAZYNmMwOYcCFx3Kpg10cyE1ypLvss8JJfQ

import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Link,
    CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

import ConfigForm from "./ConfigForm/ConfigForm";
import { Accounts, Account } from "./ConfigForm/accounts.interface";
import settingsService from "../../../services/settings";
import Canceled from "./Canceled/Canceled";
export default function Configuration(props: {}) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(false);
    const [canceled, setCanceled] = useState<null | Date>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formMessage, setFormMessage] = useState("");

    const [settings, setSettings] = useState<Accounts>();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountGroups, setAccountGroups] = useState<Account[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await settingsService.getData();
                if (!data) {
                    setMessage(t("errorRetrieve"));
                    setError(true);
                    return;
                }

                setAccounts([
                    {
                        number: 0,
                        name: "None",
                    },
                    ...data.accounts,
                ]);

                setAccountGroups([
                    {
                        number: 0,
                        name: "None",
                    },
                    ...data.accountGroups,
                ]);

                if (data.settings.canceledAt && !canceled) {
                    setCanceled(new Date(data.settings.canceledAt));
                    return;
                }
                /*eslint-disable eqeqeq*/
                setSettings({
                    turnover:
                        data.accountGroups?.find(
                            (account) =>
                                account.number == data.settings.turnover
                        ) || null,
                    payroll:
                        data.accounts?.find(
                            (account) => account.number == data.settings.payroll
                        ) || null,
                    inventory:
                        data.accountGroups?.find(
                            (account) =>
                                account.number == data.settings.inventory
                        ) || null,
                    fixedAssets:
                        data.accountGroups?.find(
                            (account) =>
                                account.number == data.settings.fixedAssets
                        ) || null,
                });

                setLoading(false);
            } catch (error) {
                return;
            }
        };
        getData();
    }, [t, canceled]);

    const settingsChange = (
        field_name: "turnover" | "payroll" | "inventory" | "inventory",
        newValue: number
    ) => {
        if (!settings) return;

        let account: Account | undefined = (field_name === "payroll"
            ? accounts
            : accountGroups
        )?.find((acc) => acc.number === newValue);

        settings[field_name] = account as Account;
        let newSettings = Object.assign({}, settings);
        console.log("settingsChange -> newSettings", newSettings);
        setSettings(newSettings);
    };

    const handleSave = async () => {
        setSending(true);
        let okay = await settingsService.setSettings({
            turnover: settings?.turnover?.number as number,
            payroll: settings?.payroll?.number as number,
            fixedAssets: settings?.fixedAssets?.number as number,
            inventory: settings?.inventory?.number as number,
        });
        setSending(false);
        setFormMessage("success");
        setFormSuccess(okay);
    };

    const cancel = async () => {
        if (window.confirm(t("cancelConfirm"))) {
            const resp = await settingsService.cancel();
            if (resp) {
                setCanceled(new Date());
            } else {
                setMessage(t("errorCanceling"));
                setError(true);
            }
        }
    };

    const restored = () => {
        setCanceled(null);
    };

    return (
        <Grid sm={8}>
            <Card className={loading ? classes.card : ""}>
                {canceled ? (
                    <Canceled canceled={canceled} restored={restored} />
                ) : (
                    <>
                        <CardHeader
                            title={t("configTitle")}
                            className={classes.cardHeader}
                        />
                        {loading ? (
                            <CircularProgress size={60} />
                        ) : (
                            <>
                                <CardContent>
                                    <p>{t("upperP")}</p>
                                    <table className={classes.list}>
                                        <tr>
                                            <td>
                                                <span>{t("turnover")}</span>
                                            </td>
                                            <td>
                                                {t("turnoverValue")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>{t("payroll")}</span>
                                            </td>
                                            <td>{t("payrollValue")}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>{t("inventory")}</span>
                                            </td>
                                            <td>{t("inventoryValue")}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>{t("fixedAssets")}</span>
                                            </td>
                                            <td>{t("fixedAssetsValue")}</td>
                                        </tr>
                                    </table>
                                    {t("mappingP")}
                                    {settings ? (
                                        <ConfigForm
                                            accountGroups={accountGroups}
                                            accounts={accounts}
                                            accountChange={settingsChange}
                                            settings={settings}
                                            sending={sending}
                                            handleSave={handleSave}
                                            message={formMessage}
                                            success={formSuccess}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                classes.loadingContainer
                                            }>
                                            <CircularProgress />
                                        </div>
                                    )}
                                    <h4>{t("canceling")}</h4>
                                    {t("cancelingP")}
                                    <Link
                                        color="secondary"
                                        onClick={cancel}
                                        className={classes.cancel}>
                                        {t("clickHere")}
                                    </Link>
                                    <h4>{t("terms")}</h4>
                                    {t("termsView")}
                                    <Link
                                        color="secondary"
                                        href="/conditions"
                                        className={classes.cancel}>
                                        {t("clickHere")}
                                    </Link>
                                    {error && (
                                        <Alert
                                            className={classes.alert}
                                            severity="success"
                                            color={"error"}>
                                            {message}
                                        </Alert>
                                    )}
                                </CardContent>
                            </>
                        )}
                    </>
                )}
            </Card>
        </Grid>
    );
}

const useStyles = makeStyles({
    cardHeader: {
        textAlign: "center",
    },
    card: {
        textAlign: "center",
    },
    subtitle: { marginBottom: 10 },
    actions: {
        display: "block",
        textAlign: "center",
        padding: 20,
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
    },
    alert: {
        marginTop: 10,
    },
    list: {
        marginBottom: 10,
        marginLeft: 5,
        "& span": {
            fontWeight: "bold",
            marginRight: 10,
        },
    },
    cancel: {
        cursor: "pointer",
    },
    cancelAlert: {
        fontSize: 16,
    },
    revokeP: {
        fontSize: 14,
        paddingLeft: 20,
    },
});
