import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Views/Auth/Login/Login";
import GTC from "./Views/Auth/GTC/GTC";
import Dashboard from "./Views/Dashboard/Dashboard";
import Callback from "./Views/Auth/Callback/Callback";
import GuardedRoute from "./Components/GuardedRoute/GuardedRoute";
import AuthRoute from "./Components/GuardedRoute/AuthRoute";
import Conditions from "./Views/Dashboard/Conditions/Conditions";
function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/gtc">
                        <GTC />
                    </Route>
                    <GuardedRoute
                        path="/configuration"
                        Component={<Dashboard />}
                    />
                    <GuardedRoute
                        path="/conditions"
                        Component={<Conditions />}
                    />
                    <Route path="/oauth/bexio/callback">
                        <Callback />
                    </Route>
                    <AuthRoute path="/" Component={<Login />} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
