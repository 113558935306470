import i18next from "i18next";
import en from "./en";
import de from "./de";
import fr from "./fr";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(LanguageDetector)
    .use(initReactI18next).init({
  debug: true,
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
  },
});

export default i18next;
