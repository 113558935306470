import React, { useEffect, useState } from "react";
import authService from "../../../services/auth";
import { makeStyles, CircularProgress, Button, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Callback(props: any) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            let url = new URLSearchParams(window.location.search);

            let error = url.get("error");
            if (error) {
                setLoading(false);
                setSuccess(false);
                setTimeout(() => {
                    props.history.push("/");
                }, 3000);
                return;
            }
            const codeVerifier = localStorage.getItem("codeVerifier");
            let success = await authService.verifyCode(
                window.location.toString(),
                codeVerifier as string
            );
            setSuccess(success);
            setLoading(false);
            if (success) props.history.push("/configuration");
        };
        verifyAuth();
    }, [props.history]);

    return (
        <div className={classes.container}>
            {loading ? (
                <CircularProgress size="50px" />
            ) : success ? (
                <h3 className={classes.success}>{t("loginSuccess")}</h3>
            ) : (
                <Grid>
                    <h3 className={classes.error}>{t("errorLogin")}</h3>
                    <Button href="/" fullWidth>
                        {t("tryAgain")}
                    </Button>
                </Grid>
            )}
        </div>
    );
}

const useStyles = makeStyles({
    container: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
    },
    error: {
        color: "#e74c3c",
    },
    success: {
        color: "#2ecc71",
    },
});

export default withRouter(Callback);
