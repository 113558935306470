import axios, { AxiosError } from "axios";

const getAxiosInstance = () => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });
    const token = localStorage.getItem("access_token");

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.request.use(
        (config) => config,
        (error: AxiosError) => {
            console.log("caught", error);
            if (parseInt(error.code as string) === 401) {
                localStorage.removeItem("access_token");
                window.location.href = "/";
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default getAxiosInstance;
