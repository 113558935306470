const en = {
    configTitle: "Configuration of Smart Insurance",
    canceledAt: "You canceled your Smart Insured at ",
    upperP:
        "Getting started with Smart Insurance is easy, all you need to do is map your turnover, payroll, inventory and fixed assets to the correct account groups/numbers in bexio. By default in bexio these accounts are set as the following:",
    turnover: "Turnover",
    turnoverValue: "Operating income from deliveries and services (3)",
    payroll: "Payroll",
    payrollValue: "Wage expense (5000)",
    inventory: "Inventory",
    inventoryValue: "Inventories and unbilled services (120)",
    fixedAssets: "Fixed Assets",
    fixedAssetsValue: "Mobile property, plant and equipment (150)",
    mappingP:
        "If you or your accountant has changed the default accounts please map each category to the correct account:",
    canceling: "Canceling Smart Insurance:",
    cancelingP:
        "If you no longer wish to benefit from Smart Insurance coverage, please ",
    clickHere: "click here",
    terms: "Terms & Conditions Page:",
    save: "Save",
    cancel: "If you want to cancel Smart Insured click here",
    errorCanceling: "There was an error while canceling your Smart Insured.",
    successSettings: "Your settings have been saved successfully!",
    errorRetrieve: "An error has occurred  while retrieving your settings.",
    none: "None",
    bexioLogin: "Login using Bexio account",
    conditions: "Conditions",
    cancelBtn: "Cancel",
    approve: "Approve",
    errorLogin: "An error has occured while trying to log you in.",
    tryAgain: "Try again",
    logout: "Logout",
    english: "English",
    german: "German",
    cancelConfirm: "Are you sure you want to cancel Smart Insured?",
    revoke1: "You can revoke the access of “Smart Insured” to your bexio data ",
    revokeHere: "here",
    revoke2: "",
    restore: "Restore",
    errorRestore: "An error has occured while trying to restore your settings.",
    termsView: "To view the terms and conditions, ",
    goConfig: "Go to configuration",
};

export default en;
