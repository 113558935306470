const fr = {
  configTitle: "Configuration de Smart Insurance",
  canceledAt: "Vous avez annulé votre Smart Insured à ",
  upperP:
    "Il est facile de démarrer avec Smart Insurance, il suffit de faire correspondre votre chiffre d'affaires, vos salaires, vos stocks et vos immobilisations aux groupes/numéros de comptes corrects dans bexio. Par défaut dans bexio, ces comptes sont définis comme suit :",
  turnover: "Chiffre d'affaires",
  turnoverValue: "Produits d'exploitation des biens et services (3)",
  payroll: "Paie",
  payrollValue: "Charges salariales (5000)",
  inventory: "Inventaire",
  inventoryValue: "Stocks et services non facturés (120)",
  fixedAssets: "Actifs immobilisés",
  fixedAssetsValue: "Immobilisations mobiles (150)",
  mappingP:
    "Si vous ou votre comptable avez modifié les comptes par défaut, veuillez faire correspondre chaque catégorie au compte correct :",
  canceling: "Annulation de l'assurance intelligente :",
  cancelingP:
    "Si vous ne souhaitez plus bénéficier de la couverture de Smart Insurance, veuillez ",
  clickHere: "cliquez ici",
  terms: "Page des conditions générales :",
  save: "Enregistrer",
  cancel: "Si vous voulez annuler Smart Insured cliquez ici",
  errorCanceling:
    "Il y a eu une erreur lors de l'annulation de votre Smart Insured.",
  successSettings: "Vos paramètres ont été enregistrés avec succès!",
  errorRetrieve:
    "Une erreur s'est produite lors de la récupération de vos paramètres.",
  none: "Aucune",
  bexioLogin: "Se connecter en utilisant le compte Bexio",
  conditions: "Conditions",
  cancelBtn: "Annuler",
  approve: "Approuver",
  errorLogin: "Une erreur s'est produite en essayant de vous connecter.",
  tryAgain: "Essayez à nouveau",
  logout: "Déconnexion",
  english: "Anglais",
  german: "Allemand",
  cancelConfirm: "Vous êtes sûr de vouloir annuler Smart Insured ?",
  revoke1:
    'Vous pouvez révoquer l\'accès de "Smart Insured" à vos données bexio ',
  revokeHere: "ici",
  revoke2: "",
  restore: "Restaurer",
  errorRestore:
    "Une erreur s'est produite lors de la tentative de restauration de vos paramètres.",
  termsView: "Pour consulter les conditions générales, ",
  goConfig: "Aller à la configuration",
  conditionTitle: "Texte de consentement",
  conditionPart1: "J’ai une assurance entreprise auprès de la Mobilière. Je consens à ce que bexio transmette à la Mobilière la raison sociale, l'adresse et l'IDE (numéro d'identification de l'entreprise) afin de vérifier le contrat d'assurance existant.",
  conditionPart2: "J'utilise régulièrement (si nécessaire en collaboration avec mon mandataire) les services comptables de bexio (au moins dans le cadre des comptes annuels). Le chiffre d'affaires, les données salariales et l'inventaire sont toujours à jour.",
  conditionPart3: "J'accepte que bexio transmette à la Mobilière toute modification concernant l'assurance, à savoir le chiffre d'affaires annuel, les salaires AVS, l'inventaire et le stock (si disponible). Une fois par an, la Mobilière compare le chiffre d'affaires annuel et le montant du salaire annuel AVS avec les valeurs de ma police d'assurance. Les modifications apportées à l'inventaire et au stock  (s'il y a lieu) sont régulièrement contrôlées. En cas de changements importants (+/- 10% par rapport à l'année précédente), bexio en informe la Mobilière. La Mobilière est autorisée à transmettre à bexio, à titre d'information, les valeurs de l'entreprise prises en compte pour ma couverture d'assurance (chiffre d'affaires annuel, total du salaire annuel AVS, total d’inventaire, stock si disponible).",
  conditionPart4: "Je reconnais que l'activation ou la désactivation de l'application \"Smart Insurance\" entraînera des modifications de mon assurance entreprise existante. En activant l'application \"Smart Insurance\", j'accepte que mon assurance entreprise soit ajustée de telle sorte que la condition spéciale individuelle (CSi) permettant de renoncer au crédit de toute sous-assurance soit intégrée dans le contrat d'assurance. Pour l'ajustement du contrat, le consultant en assurance responsable de la la Mobilière vous contactera. En cas de désactivation de l'application \"Smart Insurance\", de non-respect des conditions d'utilisation (utilisation de la comptabilité dans bexio) ou si la relation contractuelle avec bexio est résiliée, la Mobilière est en droit d'adapter unilatéralement le contrat d'assurance et d'exclure à nouveau la CSi. Je reconnais que ni l'inclusion ni l'exclusion de la CSi ne me donne le droit de résilier le contrat, même si cela entraîne un ajustement des primes. Dans ce contexte, j'accepte la suppression du passage pertinent des CGA. La Mobilière s'engage à traiter les données reçues dans le cadre de \"Smart Insurance\" de manière confidentielle. Les deux parties peuvent révoquer l'accord à tout moment. En tant qu'utilisateur de bexio, vous pouvez le faire simplement en supprimant l'application la Mobilière dans vos applications liées.",
  conditionPart5: "Condition spéciale individuelle \"Smart Insurance\" pour les clients de bexio",
  conditionPart6: "En vertu d'une modification partielle des Conditions générales, la Mobilière renonce au calcul de toute sous-assurance. En cas de dommages causés par des forces naturelles à des biens soumis à l'ordonnance sur la surveillance des assurances privées (OAV), le montant de la sous-assurance non déduit sera indemnisé dans les limites des coûts et prestations supplémentaires (non OAV) énumérés dans la description du module correspondant. Après un sinistre, la Mobilière a toutefois le droit de compenser la prime économisée grâce à la sous-assurance pour l'année d'assurance en cours et d'exiger la correction du contrat.",
  conditionPart7: "*Assurance entreprise et bâtiment de la Mobilière: annulation de l'assurance - En cas de modification des taux de prime et des franchises : Si nous modifions les tarifs des primes ou la réglementation des franchises, nous avons le droit d’adapter les assurances en conséquence. Nous vous informons de toute modification au plus tard 25 jours avant la fin de l’année d’assurance. Si vous n’acceptez pas la modification, vous pouvez résilier la partie concernée de la police. Votre résiliation est valable si elle nous parvient au plus tard le dernier jour de l’année d’assurance. À défaut de résiliation dans ce délai, la modification est réputée acceptée."
};

export default fr;
