import React, { ReactElement } from "react";
import { Redirect, Route } from "react-router-dom";
import authService from "../../services/auth";

export default function AuthRoute(props: {
  Component: ReactElement;
  path: string;
}) {
  return (
    <Route
      render={(routeProps) =>
        !authService.isAuthenticated() ? (
          props.Component
        ) : (
          <Redirect to="/configuration" />
        )
      }
      path={props.path}
    />
  );
}
