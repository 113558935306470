import React from "react";
import Header from "../../../Components/Header/Header";
import { Check } from "@material-ui/icons";
import {
    Button,
    Card,
    CardContent,
    CardActions,
    makeStyles,
    CardHeader,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConditionsText from "../../../Components/ConditionsText/ConditionsText";

const useStyles = makeStyles({
    card: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "65%",
        transform: "translate(-50%,-50%)",
    },
    actions: {
        justifyContent: "flex-end",
    },
});

export default function Conditions() {
    const { t } = useTranslation();

    const classes = useStyles();
    return (
        <>
            <Header absolute />
            <div className="container">
                <Card className={classes.card}>
                    <CardHeader title={t("terms")} />
                    <CardContent>
                        <ConditionsText />
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <Button href="/configuration" startIcon={<Check />}>
                            {t("goConfig")}
                        </Button>
                    </CardActions>
                </Card>
            </div>
        </>
    );
}
